import { connect } from 'react-redux'
import { 
    equipmentHyperlink,
    loadSkillsList,
    loadPageList
} from '../actions'
import EquipmentMagicItems from '../components/EquipmentMagicItems';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
        pagelist: state.pagelist,
        skillslist: state.skillslist
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        },
        loadPageList: value => {
            dispatch(loadPageList(value))
        },
        equipmentHyperlink(pages, skills, equipment) {
            dispatch(equipmentHyperlink(pages, skills, equipment))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentMagicItems)
