import { connect } from 'react-redux'
import {
    loadSkillsList
} from '../actions'
import SkillsRoot from '../components/SkillsRoot';

const mapStateToProps = state => {
    return {
        destination: state.destination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillsRoot)
