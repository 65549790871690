import { call, put, takeEvery } from 'redux-saga/lib/effects'
import c from '../constants'
import { loadTemplateListSuccess, loadTemplateSuccess } from '../actions'
import { loadTemplateListData, loadTemplateData } from '../api'

export function* watchLoadTemplateListAsync(action) {
    try {
      let data = yield call(loadTemplateListData, action.payload)

      yield put(loadTemplateListSuccess(data))
      } catch(e) {
        console.error(e);
      }
    }

export function* watchLoadTemplateListData(action) {
    yield takeEvery (
        c.actions.LOAD_TEMPLATE_LIST, 
        watchLoadTemplateListAsync
      )
  }
  
  export function* watchLoadTemplateAsync(action) {
    try {
      let data = yield call(loadTemplateData, action.payload)
      yield put(loadTemplateSuccess(data))
      } catch(e) {
        console.error(e);
      }
  }

export function* watchLoadTemplateData(action) {
    yield takeEvery (
        c.actions.LOAD_TEMPLATE, 
        watchLoadTemplateAsync
      )
  }