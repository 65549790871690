import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './TypeControl.css'
import { Container, Row, Col, Table } from 'reactstrap'

class DrugsPoisonIllicit extends Component {
  
    async componentDidMount() {

    }

    render() {
        const equipmentlist = this.props.equipmentlist 
            ? this.props.equipmentlist.filter(t => t.type === 'drugspoisonillicit') 
            : []

        return (equipmentlist && equipmentlist.length > 0) 
        ?  <Container fluid={true}>
            <Row>
                <Col lg="12">
                    <h5>Drugs, Poisons, and Illicit Goods</h5>
                </Col>
            </Row>
            <Row>
                <Col lg="12"> 
               
                </Col>
            </Row> 
            <Row>
                <Col lg="12">
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                <Table striped>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Delivery</th>
                                <th>Onset</th>
                                <th>Damage</th>
                                <th>Damage Limit</th>
                                <th>Weight</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                            {
                                (equipmentlist)
                                ?   equipmentlist.map( r => 
                                    <tbody key={r.key} className="bottom-border">                                       
                                        <tr className="bolder-headings">
                                            <td>{ r.name }, { r.quality }</td>
                                            <td>{ r.delivery }</td> 
                                            <td>{ r.onset }</td>
                                            <td>{ r.damage }</td>
                                            <td>{ r.damagelimit }</td>
                                            <td>{ r.weight }</td>
                                            <td>{ r.quantity }</td>
                                            <td>{ r.price } g</td>
                                        </tr> 
                                    </tbody>
                                        )
                                    : <tr><td>No items...</td></tr>
                            }
                    </Table>                  
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                </Col>
            </Row>
        </Container>
        : <div></div>
    }
}

export default DrugsPoisonIllicit;