import { connect } from 'react-redux'
import { 
    equipmentHyperlink,
    loadSkillsList
} from '../actions'
import EquipmentWeapons from '../components/EquipmentWeapons';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
        skillslist: state.skillslist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        equipmentHyperlink() {
            dispatch(equipmentHyperlink())
        },
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        },
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentWeapons)
