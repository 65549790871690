
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'
import './Template.css';
import Inventory from '../containers/Inventory/Inventory'

class Template extends Component {
  async componentDidMount() {
    const {
      listtype,
      loadTemplate,
      destination,
      loadTaxonomy
    } = this.props

    await loadTemplate(listtype, destination)
    await loadTaxonomy()
  }

  render() {
    let taxonomies = this.props.taxonomies
    let template = this.props.currenttemplate
    let listtype = this.props.listtype
    if (template && taxonomies) this.props.hyperlinkTemplate(template)

    const naturalattacks = (template && template.skills) 
        ? template.skills.filter(s => s.key === '88bdf439-db76-4541-b223-23f762097fc8' && s.techtypekey !== '') : []

    return (
      <div>
      {
      (template) 
        ? <Container>
            <Row>
                <Col sm="12"> 
                {
                    (listtype ==='monsters')
                        ? <h3 className="less-bottom">{template.race} {(template.heritage && template.heritage.indexOf(template.race) > -1) 
                                ? template.heritage.replace(template.race, '') 
                                : (template.heritage) 
                                    ? template.heritage + ',' 
                        : <span></span> }
                            <span>{template.profession}</span></h3>
                        : <h3 className="less-bottom">{ template.race ||  template.heritage || template.profession }</h3>
                }
                
                </Col>
            </Row>           
            <Row>
                <Col md="12">
                    <div dangerouslySetInnerHTML={{ __html: template.textsections.filter(f => f.title==="Introduction")[0].body }}></div>
                </Col>
            </Row>
            <Row>
                <Col sm="12"> 
                <h5> <div className="character-score">{ ( template.foci && template.foci.filter(item => item.key === "focus-body").length > 0) ? 'Body: ' + template.foci.filter(item => item.key === "focus-body")[0].score.toFixed(4) : null } </div> </h5>
                </Col>
            </Row>           
            <Row>
                <Col sm="12"> 
                <h5> <div className="character-score">{ ( template.foci && template.foci.filter(item => item.key === "focus-mind").length > 0)  ? 'Mind: ' + template.foci.filter(item => item.key === "focus-mind")[0].score.toFixed(4) : null }</div> </h5>
                </Col>
            </Row>  <Row>
                <Col sm="12"> 
                <h5> <div className="character-score">{ ( template.foci && template.foci.filter(item => item.key === "focus-spirit").length > 0)  ? 'Spirit: ' + template.foci.filter(item => item.key === "focus-spirit")[0].score.toFixed(4) : null }</div> </h5>
                </Col>
            </Row>
            <Row className="top-margin">
                <Col xs="12" sm="3" md="3" className=""> 
                <h5>{ (template.hp) ? template.hp.toFixed(2) : 0 } Hit Points </h5>
                </Col> 
                <Col xs="12" sm="3" md="3" className=""> 
                <h5>{ (template.qp) ? template.qp.toFixed(2) : 0 } Quit Points </h5>
                </Col>
                <Col xs="12" sm="3" md="3" className="">
                <h5>{ (template.mana) ? template.mana.toFixed(2) : 0 } Mana </h5>
                </Col>
                <Col xs="12" sm="3" md="3" className="">
                <h5>{ (template.mitigation) ? template.mitigation.toFixed(2) : 0 } Mitigation </h5>
                </Col>
            </Row>
            <Row>
                <Col sm="12" lg="12" className="padding-0">
                    <Container fluid={true} className="skills-grid padding-0">
                        <Row>
                        {
                            (template.skills)
                            ? template.skills.map(skill => { 
                                let realm = template.realms.filter(f => f.key === skill.realmkey)[0]
                                let focus = template.foci.filter(f => f.key === skill.focuskey)[0]
                                let skillsc = template.skillscores.filter(f => f.key === skill.skillkey && f.skilltypekey === skill.skilltypekey)[0]

                                return <Col xs="12" sm="6" md="4" lg="4" className="padding-right-0" key={skill.key + skill.skilltypekey + skill.techtypekey}>
                                        <div className="skillContainer">
                                            <div><div className="score">{ parseFloat(skill.techniquescore).toFixed(2) }</div> <span className="techniquebox">{ skill.techniquename }</span> <span className="small-green-text">{ (skill.techniquetype) ? `(${skill.techniquetype})` : ''}</span> <span className="mild-emphasis"> { !skill.techniquetype && skill.techniquename.length < 20 ? 'Technique': null } </span></div>
                                            {
                                                (skill.magicscores)
                                                    ? skill.magicscores.map(magsco => <div key={magsco.equipitem}><span className="score">{parseFloat(magsco.magicscore).toFixed(2)}</span> {magsco.equipitem}</div>)
                                                    : null
                                            }
                                            <div><span className="score">{ skillsc.score.toFixed(2) }</span> <a className="darker-link" target="_blank" rel="noopener noreferrer" href={`/?p=bookofskills&v=${skill.skillkey}`}> {skillsc.name}</a> <span className="small-blue-text">{ (skill.skilltype) ? `(${skill.skilltype})` : ''}</span> <span className="mild-emphasis">{ !skill.skilltype && skillsc.name.length < 20 ? 'Skill': null }</span></div>
                                            <div><span className="score">{ realm.score.toFixed(2) }</span> { realm.name } <span className="mild-emphasis">Realm</span></div>
                                            <div><span className="score">{ focus.score.toFixed(2) }</span> { focus.name } <span className="mild-emphasis">Focus</span> </div>
                                            <div><span className="score">{ template.level.toFixed(2) }</span> <span className="mild-emphasis">Char Level </span></div>
                                            <div><span className="score">{ skill.sizeadj.toFixed(2) }</span> <span className="mild-emphasis">Size</span></div>
                                            <div><span className="score">{ skill.builtin.toFixed(2) } </span> <span className="mild-emphasis">Built-in</span></div>
                                            <div><span className="score"> -{ ((parseFloat(template.armorpenalty) + parseFloat(template.magicarmorpenalty || 0)) * skill.armorpenalty).toFixed(2) } </span> <span className="mild-emphasis">Armor Penalty</span></div>
                                                
                                        <div id="total" className="skill-button"> { skill.total ? parseFloat(skill.total).toFixed(2) : 0.0 } </div>    
                                    </div>
                                </Col>
                            })
                            : null
                        }
                        </Row>
                    </Container>
                </Col>
            </Row> 
            <Row>
                <Col xs="12" sm="3" md="3" className=""> 
                    <div>Size: <span className="emphasis">{ template.size}</span></div>
                    <div>Endurance: <span className="emphasis">{ template.endurancehours }</span>  hours <span className="emphasis">{ template.enduranceminutes}</span></div>
                    <div>Age Category: <span className="emphasis">  { template.agecategory } </span></div>
                    <div>Age: <span className="emphasis"> { Math.floor(template.age)} </span> </div>
                    <div>Strength: <span className="emphasis"> { template.strength } </span> kg </div>
                    <div>Social Class: <span className="emphasis"> { template.socialclass } </span></div>
                </Col>  
                <Col  xs="12" sm="3" md="3" className="">
                    <div>Light Vision: <span className="emphasis">6 / { template.lightvision }</span></div>
                    <div>Night Vision: <span className="emphasis"> { (template.nightvision) ? template.nightvision.toFixed(1) : 0 }</span></div>
                    <div>Infrared Vision: <span className="emphasis">6 / { template.infrared }</span></div>
                    <div>Hearing: <span className="emphasis"> { template.hearing } </span> dB</div>
                    <div>Frequency: <span className="emphasis"> { template.frequency } </span></div> 
                    <div>Smell: <span className="emphasis">{ template.smell }M</span> Receptors </div>
                    <div>Touch: <span className="emphasis">{ template.touch }</span> </div>
                    <div>Taste: <span className="emphasis">{ template.taste }</span> </div>
                </Col>  
                <Col xs="12" sm="3" md="3" className="">
                    <div>Walking: <span className="emphasis">{ template.walkingspeed }</span>  mps</div>
                    <div>Swimming: <span className="emphasis"> { template.swimmingspeed }</span> mps</div>
                    <div>Climbing: <span className="emphasis">{ template.climbingspeed }</span> mps</div>
                    <div>Flying: <span className="emphasis"> { template.flyingspeed } </span> mps</div>
                    <div>Burrowing: <span className="emphasis"> { template.burrowingspeed } </span> mps</div> 
                </Col>
                <Col xs="12" sm="3" md="3" className="">
                <div>Natural Reach: <span className="emphasis">{ template.naturalreach }</span> cm</div>
                    {
                        (naturalattacks && naturalattacks.length > 0) 
                        ?  naturalattacks.map(m => 
                            <div key={m.techniquetype}>{m.techniquetype}: <span className="emphasis"> { Math.floor(m.techniquescore / 2) }d10</span></div>
                            )
                        : null
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col>
                <div>Currency: <span className="emphasis">{template.gold} g {template.silver} s {template.copper} c</span></div>
                </Col>
            </Row>
            <Row>
                <Col>
                {
                    
                }
                {
                    template.textsections.sort((a,b) => a.sortorder - b.sortorder).filter(f => f.title !== "Introduction").map(m => 
                        <div key={m.title}>
                            <h3>{ m.title }</h3>
                            <div dangerouslySetInnerHTML={{ __html: m.body }}></div>
                        </div>
                    )
                }
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Inventory />
                </Col>
            </Row>
            </Container>
        : <div></div>
      }
      </div>
    )
  }
}

export default Template;



