import { connect } from 'react-redux'
import Containers from '../../components/Inventory/TypeControls/Containers'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Containers)