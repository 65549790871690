import { connect } from 'react-redux'
import { 
    loadNavType, 
} from '../actions'
import Root from '../components/Root'

const mapStateToProps = state => {
    return {
        navtype: state.navtype,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadNavType: (loadType, destination, listtype, anchortarget) => {
            dispatch(loadNavType(loadType, destination, listtype, anchortarget))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(Root)
