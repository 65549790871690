import c from './constants'

export const loadPageData = async params => {
    let split = params.id.split("|")

    const response = await fetch(
        `${c.url}/${split[0]}/${split[1]}.json`
    )
    return response.json()
}

export const loadPageListData = async params => {
    const response = await fetch(
        `${c.url}/${params}/pagelist.json`
    )
    return response.json()
}

export const loadSkillsListData = async params => {
    const response = await fetch(
        `${c.url}/skills/skilllist.json`
    )
    return response.json()
}

export const loadSkillData = async params => {
    const response = await fetch(
        `${c.url}/skills/${params}.json`
    )
    return response.json()
}

export const loadTemplateListData = async params => {
    const response = await fetch(
        `${c.url}/${params}/list.json`
    )
    return response.json()
}

export const loadTemplateData = async params => {
    const response = await fetch(
        `${c.url}/${params.listtype}/${params.value}.json`
    )
    return response.json()
}

export const loadEquipmentListData = async params => {
    const response = await fetch(
        `${c.url}/equipment/equipmentlist.json`
    )
    return response.json()
}

export const loadTaxonomyData = async () => {
    const response = await fetch(
        `${c.url}/resources/taxonomy/taxonomy.json`
    )
    return response.json()
}