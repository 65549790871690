import { connect } from 'react-redux'
import DrugsPoisonIllicit from '../../components/Inventory/TypeControls/DrugsPoisonIllicit'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return { 
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugsPoisonIllicit)