import { connect } from 'react-redux'
import { 
    loadSkill,
    loadSkillsList,
    loadPageList,
    hyperlink
} from '../actions'
import Skill from '../components/Skill';

const mapStateToProps = state => {
    return {
        destination: state.destination,
        currentskill: state.currentskill,
        anchortarget: state.anchortarget,
        skillslist: state.skillslist,
        pagelist: state.pagelist
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadSkill: id => {
            dispatch(loadSkill(id))
        },
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        },
        loadPageList: book => {
            dispatch(loadPageList(book))
        },
        hyperlink(value) {
            dispatch(hyperlink('skill', value))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(Skill)
