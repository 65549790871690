
import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap'


class ResourcePagesList extends Component {
  changeDestination(key) {
      this.props.changeDestination(key)
  }

  render() {
    let taxonomies = this.props.taxonomies

    return (
      <Container fluid={true}>
        <Row>
            <Col lg="12">
             
            </Col>
        </Row>
        <Row>
            <Col>
               &nbsp;
            </Col>
        </Row>
        <Row>
            <Col>
                <h3>Taxonomy</h3>
            </Col>
        </Row>
        <Row>
              <Col lg="12">
                {
                (taxonomies)
                    ? taxonomies.map(m => 
                        <div>


                        </div>
                    )
                    : <div></div>
                }
            </Col>
        </Row>
        <Row>
            <Col>
               &nbsp;
            </Col>
        </Row>
      </Container>
    );
  }
}

export default ResourcePagesList;



