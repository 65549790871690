import { connect } from 'react-redux'
import Armor from '../../components/Inventory/TypeControls/Armor'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {       

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Armor)