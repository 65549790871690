
import React, { Component } from 'react';
import Skill from '../containers/Skill'
import SkillsList from '../containers/SkillsList'

class SkillsRoot extends Component {
  async componentDidMount() {
    const {
      loadSkillsList
    } = this.props

    await loadSkillsList()
  }

  render() {
    let destination = this.props.destination
    return (
      <div>     
        {
         (destination) 
         ? <Skill />
         : <SkillsList />
        }
      </div>
    );
  }
}

export default SkillsRoot;



