import c from './constants'

export const loadPage = (id, book) => ({
    type: c.actions.LOAD_PAGE,
    payload: { id, book }
})

export const loadPageList = book => ({
    type: c.actions.LOAD_PAGE_LIST,
    payload: book
})

export const loadPageListSuccess = (value) => ({
    type: c.actions.LOAD_PAGE_LIST_SUCCESS,
    payload: value
})

export const loadSkillsList = () => ({
    type: c.actions.LOAD_SKILLS_LIST,
    payload: null
})

export const loadSkill = id => ({
    type: c.actions.LOAD_SKILL,
    payload: id
})

export const loadSkillSuccess = data => ({
    type: c.actions.LOAD_SKILL_SUCCESS,
    payload: data
})

export const loadSkillsListSuccess = skillslist => ({
    type: c.actions.LOAD_SKILLS_LIST_SUCCESS,
    payload: skillslist
})

export const loadTemplateList = value => ({
    type: c.actions.LOAD_TEMPLATE_LIST,
    payload: value
})

export const loadTemplate = (listtype, value) => ({
    type: c.actions.LOAD_TEMPLATE,
    payload: { listtype, value }
})

export const loadTemplateSuccess = value => ({
    type: c.actions.LOAD_TEMPLATE_SUCCESS,
    payload: value
})

export const loadTemplateListSuccess = value => ({
    type: c.actions.LOAD_TEMPLATE_LIST_SUCCESS,
    payload: value
})

export const loadPageSuccess = (body) => ({
    type: c.actions.LOAD_PAGE_SUCCESS,
    payload: body
})

export const loadEquipmentList = value => ({
    type: c.actions.LOAD_EQUIPMENT_LIST,
    payload: value
})

export const loadEquipmentListSuccess = value => ({
    type: c.actions.LOAD_EQUIPMENT_LIST_SUCCESS,
    payload: value
})

export const loadNavType = (loadtype, destination, listtype, anchortarget) => ({
    type: c.actions.LOAD_NAV_TYPE,
    payload: { loadtype, destination, listtype, anchortarget }
})

export const unwindGear = tempcharacter => ({
    type: c.actions.UNWIND_GEAR,
    payload: tempcharacter
})

export const changeDestination = key => ({
    type: c.actions.CHANGE_DESTINATION,
    payload: key
})

export const hyperlink = (type, value) => ({
    type: c.actions.HYPERLINK,
    payload: {type, value}
})

export const equipmentHyperlink = (pages, skills, equipment) => ({
    type: c.actions.EQUIPMENT_HYPERLINK,
    payload: {pages, skills, equipment}
})

export const loadTaxonomy = () => ({
    type: c.actions.LOAD_TAXONOMY,
    payload: null
})

export const loadTaxonomySuccessful = data => ({
    type: c.actions.LOAD_TAXONOMY_SUCCESSFUL,
    payload: data
})

export const hyperlinkTemplate = template => ({
    type: c.actions.HYPERLINK_TEMPLATE_TAXONOMY,
    payload: template
})