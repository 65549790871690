export default  {
    url: "..",
    actions: {
            LOAD_PAGE                           : "LOAD_PAGE",
            LOAD_PAGE_SUCCESS                   : "LOAD_PAGE_SUCCESS",
            LOAD_PAGE_LIST                      : "LOAD_PAGE_LIST",
            LOAD_PAGE_LIST_SUCCESS              : "LOAD_PAGE_LIST_SUCCESS",
            LOAD_NAV_TYPE                       : "LOAD_NAV_TYPE",
            LOAD_SKILLS_LIST                    : "LOAD_SKILLS_LIST",
            LOAD_SKILLS_LIST_SUCCESS            : "LOAD_SKILLS_LIST_SUCCESS",
            LOAD_SKILL                          : "LOAD_SKILL",
            LOAD_SKILL_SUCCESS                  : "LOAD_SKILL_SUCCESS",
            LOAD_TEMPLATE_LIST                  : "LOAD_TEMPLATE_LIST",
            LOAD_TEMPLATE_LIST_SUCCESS          : "LOAD_TEMPLATE_LIST_SUCCESS",
            LOAD_TEMPLATE                       : "LOAD_TEMPLATE",
            LOAD_TEMPLATE_SUCCESS               : "LOAD_TEMPLATE_SUCCESS",
            LOAD_EQUIPMENT_LIST                 : "LOAD_EQUIPMENT_LIST",
            LOAD_EQUIPMENT_LIST_SUCCESS         : "LOAD_EQUIPMENT_LIST_SUCCESS",
            UNWIND_GEAR                         : "UNWIND_GEAR",
            CHANGE_DESTINATION                  : "CHANGE_DESTINATION",
            HYPERLINK                           : "HYPERLINK",
            EQUIPMENT_HYPERLINK                 : "EQUIPMENT_HYPERLINK",
            LOAD_TAXONOMY                       : "LOAD_TAXONOMY",
            LOAD_TAXONOMY_SUCCESSFUL            : "LOAD_TAXONOMY_SUCCESSFUL",
            HYPERLINK_TEMPLATE_TAXONOMY         : "HYPERLINK_TEMPLATE_TAXONOMY"
    }
}