import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './TypeControl.css'
import { Container, Row, Col, Table } from 'reactstrap'

class Ammunition extends Component {
  
    async componentDidMount() {
     
    }

    render() {
        const equipmentlist = (this.props.equipmentlist) 
                ? this.props.equipmentlist.filter(t => t.type === 'ammunition') 
                : []

        return (equipmentlist && equipmentlist.length > 0) 
        ?  <Container fluid={true}>
            <Row>
                <Col lg="12">
                    <h5>Ammunition</h5>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                <Table striped>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Damage Type</th>
                                <th>Range Adjustment</th>
                                <th>Weight</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                            {
                                (equipmentlist)
                                ?   equipmentlist.map(r => 
                                    <tbody key={r.key} className="bottom-border">                                       
                                        <tr className="bolder-headings">
                                        <td>{ (r.damageadj || r.magicrangeadj || r.magicweightadj) 
                                                ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                                : <span>{r.name}, { r.quality }</span> 
                                            }</td>
                                            <td>{ r.damagetype } <span className="soft-blue under-emphasis"> {
                                                (r.damageadj)
                                                    ? r.damageadj > 0
                                                    ? '+' + r.damageadj 
                                                    : r.damageadj
                                                : null
                                                }</span></td>
                                            <td>{ r.rangeadjustment } <span className="soft-blue under-emphasis"> {
                                                (r.magicrangeadj)
                                                    ? r.magicrangeadj > 0
                                                        ? '+' + r.magicrangeadj 
                                                        : r.magicrangeadj
                                                    : null
                                                    }</span></td>
                                            <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                                (r.magicweightadj)
                                                    ? r.magicweightadj > 0 
                                                        ? '+' + r.magicweightadj + 'kg'  
                                                        : r.magicweightadj + 'kg' 
                                                    :null
                                                    }</span></td>
                                            <td>{ r.price } g</td>
                                        </tr>
                                    </tbody>
                                )
                            : <tr><td>No items...</td></tr>
                            }
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                </Col>
            </Row>
        </Container>
        : <div></div>
    }
}

export default Ammunition;