import { connect } from 'react-redux'
import {
    loadTaxonomy
} from '../actions'
import ResourcePagesRoot from '../components/ResourcePagesRoot';

const mapStateToProps = state => {
    return {
        destination: state.destination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadTaxonomy: () => {
            dispatch(loadTaxonomy())
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePagesRoot)
