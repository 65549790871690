import React, { Component } from 'react';
import { Table } from 'reactstrap'

class EquipmentWeapons extends Component {
  async componentDidMount() {


  }

  render() {    
    const equipmentlist = (this.props.equipmentlist) 
        ? this.props.equipmentlist.filter(t => t.type === 'weapons') 
        : []

    return (
        <div>                    
            <h2>Weapons ({ equipmentlist.length } Items)</h2>
            <p>
                Weapons come in a dizzying array. Most weapons are grouped by size and weight, or by category. 
                Weapons are further described by damage type.
            </p>
            <div className="row">
            <Table striped>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Damage</th>
                        <th>Damage Type</th>
                        <th>Hands Required</th>
                        <th>Size</th>
                        <th>Weapon Reach</th>
                        <th>Ranged</th>
                        <th>HP</th>
                        <th>Weight</th>
                        <th>Price</th>
                    </tr>
                </thead>
                    {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <tbody key={r.key} className="bottom-border">                                       
                                <tr className="bolder-headings">
                                <td>{ (r.magicweightadj || (r.magicskills && r.magicskills.length > 0)) 
                                    ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                    : <span>{r.name}, { r.quality }</span> 
                                }</td>
                                <td>{ r.damage } <span className="soft-blue under-emphasis"> {
                                    (r.damageadj)
                                        ? r.damageadj > 0
                                        ? '+' + r.damageadj 
                                        : r.damageadj
                                    : null
                                    }</span></td>
                                <td>{ r.damagetype } </td>
                                <td>{ r.handsrequired }</td>
                                <td>{ r.size }</td>
                                <td>{ r.reach } <span className="soft-blue under-emphasis"> {
                                    (r.magicreachadj)
                                        ? r.magicreachadj > 0
                                        ? '+' + r.magicreachadj 
                                        : r.magicreachadj
                                    : null
                                    }</span></td>
                                <td>{ r.range } <span className="soft-blue under-emphasis"> {
                                    (r.magicrangeadj)
                                        ? r.magicrangeadj > 0
                                        ? '+' + r.magicrangeadj 
                                        : r.magicrangeadj
                                    : null
                                    }</span></td>
                                <td>{ r.hp } <span className="soft-blue under-emphasis"> {
                                    (r.magichpadj)
                                        ? r.magichpadj > 0
                                        ? '+' + r.magichpadj 
                                        : r.magichpadj
                                    : null
                                    }</span></td>
                                <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                (r.magicweightadj)
                                    ? r.magicweightadj > 0 
                                        ? '+' + r.magicweightadj + 'kg'  
                                        : r.magicweightadj + 'kg' 
                                    :null
                                    }</span></td>
                                <td>{ r.price } g</td>
                                </tr>
                                {
                                            (r.equipmentskills) 
                                            ? <tr className='borderless-row'>
                                                <td  colSpan="14"><span className="middle bolder-headings under-emphasis">Used With:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.equipmentskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                        {
                                            (r.magicskills) 
                                            ? <tr className='borderless-row'>
                                                <td colSpan="14"><span className="middle bolder-headings under-emphasis">Magic Bonuses:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.magicskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                            </tbody>
                                )
                            : <tr><td>No items...</td></tr>
                        }
                    </Table>       
                <div> {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <div>
                               <h3>{ r.name }, { r.quality }</h3>
                               <p>
                               { r.description }
                               </p>
                            </div>     
                                )
                        : <tr><td>No items...</td></tr>
                    }
                </div>
            </div>
      </div>
    );
  }
}

export default EquipmentWeapons;



