
import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'

class StaticPages extends Component {
  async componentDidMount() {
    const {
        loadPage,
        loadPageList,
        loadSkillsList,
        destination
      } = this.props

      Events.scrollEvent.register('begin', function(to, element) {
      });
   
      Events.scrollEvent.register('end', function(to, element) {
      });
   
      scrollSpy.update();
  
     await loadPageList()
     await loadSkillsList()
     await loadPage(destination)
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  changeDestination(key) {
    this.props.changeDestination(key)
  }

  render() {
    let page = this.props.page
    let anchortarget = this.props.anchortarget
    let skillslist = this.props.skillslist
    let pagelist = this.props.pagelist
    if (page && pagelist && skillslist) this.props.hyperlink(page)

    if (page && anchortarget) { 
      let elem = document.getElementById("#" + anchortarget.toLowerCase())
      if (elem) {
        let rect = elem.getBoundingClientRect()
        scroll.scrollTo(rect.top);
      }             
    }

    return (
      <Container fluid={true}>    
        <Row>
          {
          (page) 
          ? <Container fluid={true}>
              <Row>
                <Col><h2>{ page.name }</h2></Col>
              </Row>
              <Row>
                <Col dangerouslySetInnerHTML={{ __html: page.introduction }}></Col> 
              </Row>
              {
                (page.topics) 
                  ? page.topics.map(pt => 
                    <Row key={pt.key}>
                      <Col>
                        <Container fluid={true}>
                          <Row>
                            <Col>
                              <div id={pt.anchor}></div>
                              {
                                (!pt.headinglevel || pt.headinglevel === "1")
                                  ? <h3><a className="header-link"  href={pt.anchor}>{pt.topicheader}</a></h3>
                                  : (pt.headinglevel === "2") 
                                    ? <h4><a className="header-link" href={pt.anchor}>{pt.topicheader}</a></h4>
                                    : (pt.headinglevel === "3")
                                      ?  <h5><a className="header-link" href={pt.anchor}>{pt.topicheader}</a></h5>
                                      : (pt.headinglevel === "4")
                                        ? <h6><a className="header-link" href={pt.anchor}>{pt.topicheader}</a></h6>
                                        : <div>{pt.topicheader}</div>
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {
                                (!pt.headinglevel || pt.headinglevel === "1")
                                  ? <div className="ident1" dangerouslySetInnerHTML={{ __html: pt.body }}></div>
                                  : (pt.headinglevel === "2") 
                                    ? <div className="ident2" dangerouslySetInnerHTML={{ __html: pt.body }}></div>
                                    : (pt.headinglevel === "3")
                                      ?  <div className="ident3" dangerouslySetInnerHTML={{ __html: pt.body }}></div>
                                      : (pt.headinglevel === "4")
                                        ? <div className="ident4" dangerouslySetInnerHTML={{ __html: pt.body }}></div>
                                        : <div>{pt.topicheader}</div>
                              }
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    )
                  : null
              }
              <Row>
                <Col> </Col>
              </Row>
            </Container>
             
          : null

          }
        </Row> 
      </Container>
    );
  }
}

export default StaticPages;



