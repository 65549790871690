import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Root.css';
import { Container, Row, Col} from 'reactstrap'
import StaticPagesRoot from '../containers/StaticPagesRoot'
import SkillsRoot from '../containers/SkillsRoot'
import TemplateRoot from '../containers/TemplateRoot'
import EquipmentRoot from '../containers/EquipmentRoot'
import ResourcePagesRoot from '../containers/ResourcePagesRoot'

class Root extends Component {
  async componentDidMount() {
    const {
      loadNavType
    } = this.props

    let params, listtype, loadtype, destination, anchortarget
    let search = window.location.search.substring(1);
    if (search) {
      params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    }

    if (search === "") {
      loadtype = "pages"
      destination = "home"
    }
    else {      
      loadtype = params.p
      destination = params.v
      listtype = params.t
      anchortarget = params.h
    }

    await loadNavType(loadtype, destination, listtype, anchortarget)
  }

  render() {
    const navtype = this.props.navtype

    return (
      <Container fluid={true}>     
        <Row>
          <Col>
          <h1>The Reverie Online</h1>
          {
            {
              'pages': <StaticPagesRoot />,
              'bookofskills': <SkillsRoot />,
              'bookofraces': <TemplateRoot />,
              'bookofheritages': <TemplateRoot />,
              'bookofprofessions': <TemplateRoot />,
              'bookofmonsters': <TemplateRoot />,
              'equipment': <EquipmentRoot />,
              'resources':<ResourcePagesRoot />
            }[navtype]
          }
          </Col>
        </Row>
        <Row>
          <Col>
            <footer>Copyright &copy; 2012 - { new Date().getFullYear() } Daniel L. Ellis, Jr .All rights reserved.</footer>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Root;
