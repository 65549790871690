import { connect } from 'react-redux'
import { 
    loadTemplateList
} from '../actions'
import TemplateRoot from '../components/TemplateRoot';

const mapStateToProps = state => {
    return {
        destination: state.destination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadTemplateList: (value) => {
            dispatch(loadTemplateList(value))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateRoot)
