import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './TypeControl.css'
import { Container, Row, Col, Table } from 'reactstrap'

class WorkingAnimals extends Component {
  
    async componentDidMount() {

    }

    takeEquipment(key) {
        this.props.takeEquipment(key)
    }

    buyEquipment(key) {
        this.props.buyEquipment(key)
    }

    render() {
        const equipmentlist = this.props.equipmentlist 
            ? this.props.equipmentlist.filter(t => t.type === 'workinganimals') 
            : []

        return (equipmentlist && equipmentlist.length > 0) 
        ?  <Container fluid={true}>
            <Row>
                <Col lg="12">
                    <h5>Working Animals</h5>
                </Col>
            </Row>
            <Row>
                <Col lg="12"> 
               
                </Col>
            </Row> 
            <Row>
                <Col lg="12">
                </Col>
            </Row>
            <Row>
                <Col lg="12">           
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        {
                            (equipmentlist)
                            ?   equipmentlist.map( r => 
                                <tbody key={r.key} className="bottom-border">                                       
                                    <tr className="bolder-headings">
                                        <td>{ r.name }, { r.quality }</td>
                                        <td>{ r.price } g</td>
                                    </tr>
                                </tbody>
                                    )
                                : <tr><td>No items...</td></tr>
                        }
                    </Table>                
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                </Col>
            </Row>
        </Container>
        : <div></div>
    }
}

export default WorkingAnimals;