import { connect } from 'react-redux'
import LodgingServices from '../../components/Inventory/TypeControls/LodgingServices'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return { 

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LodgingServices)