//import c from '../constants';
import { createStore, applyMiddleware, compose } from 'redux'
import createDebounce from 'redux-debounced';
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import reducers from './reducers'
import sagas from './sagas'

export default function configureStore() {
const sagaMiddleware = createSagaMiddleware()
const middlewares = [
    sagaMiddleware,
    createDebounce(),
    // createLogger(),
]

const store = createStore(
    reducers,
    compose(
        applyMiddleware(...middlewares),
        window.devToolExtension ? window.devToolExtension() : f => f
    )
)

    sagaMiddleware.run(sagas)

    return store
}
