import React, { Component } from 'react';
import { Table } from 'reactstrap'

class EquipmentHouseholdGoods extends Component {
  async componentDidMount() {
   
  }

  render() {    
    const equipmentlist = (this.props.equipmentlist) 
    ? this.props.equipmentlist.filter(t => t.type === 'householdsupplies') 
    : []

    return (
        <div>                    
            <h2>Household Supplies ({ equipmentlist.length } Items)</h2>
            <p>
            Household foods are those used to fill the pantries, root cellars, and stockyards of those who eat. 
            These food items may or may not be culturally appropriate depending upon your game setting. If 
            these items do not fit, use them as a guideline for pricing the items more appropriate to your 
            culture.
            </p>
            <div className="row">
            <Table striped>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Size</th>
                        <th>Quantity</th>
                        <th>Weight</th>
                        <th>Price</th>
                    </tr>
                </thead>
                    {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <tbody key={r.key} className="bottom-border">                                       
                                <tr className="bolder-headings">
                                <td>{ (r.magicweightadj || (r.magicskills && r.magicskills.length > 0)) 
                                    ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                    : <span>{r.name}, { r.quality }</span> 
                                }</td>
                                <td>{ r.size }</td>                                            
                                <td>{ r.quantity } { r.quantityunit }</td>
                                <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                    (r.magicweightadj)
                                        ? r.magicweightadj > 0 
                                            ? '+' + r.magicweightadj + 'kg'  
                                            : r.magicweightadj + 'kg' 
                                        :null
                                        }</span></td>
                                <td>{ r.price } g</td>
                                </tr>  
                                {
                                            (r.equipmentskills) 
                                            ? <tr className='borderless-row'>
                                                <td  colSpan="14"><span className="middle bolder-headings under-emphasis">Used With:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.equipmentskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                        {
                                            (r.magicskills) 
                                            ? <tr className='borderless-row'>
                                                <td colSpan="14"><span className="middle bolder-headings under-emphasis">Magic Bonuses:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.magicskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                            </tbody>
                                )
                            : <tr><td>No items...</td></tr>
                    }
                    </Table>           
                <div> {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <div>
                               <h3>{ r.name }, { r.quality }</h3>
                               <p>
                               { r.description }
                               </p>
                            </div>     
                                )
                        : <tr><td>No items...</td></tr>
                    }
                </div>
            </div>
      </div>
    );
  }
}

export default EquipmentHouseholdGoods;



