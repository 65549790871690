import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './TypeControl.css'
import { Container, Row, Col, Table } from 'reactstrap'

class Armor extends Component {
  
    async componentDidMount() {
    }

    setCurrentEquipmentProperty(property, value) {
        this.props.setCurrentEquipmentProperty(property, value)
    }

    changeQuality(value, key) {
        this.props.changeQuality(value, key)
    }

    editEquipment(key) {
        this.props.editEquipment(key)
    }

    deleteEquipment(key) {
        this.props.deleteEquipment(key)
    }

    takeEquipment(key) {
        this.props.takeEquipment(key)
    }

    buyEquipment(key) {
        this.props.buyEquipment(key)
    }

    saveChanges(key) {
        this.props.saveChange(key)
    }

    render() {
        const equipmentlist = this.props.equipmentlist 
            ? this.props.equipmentlist.filter(t => t.type === 'armor') 
            : []

        return (equipmentlist && equipmentlist.length > 0) 
        ?  <Container fluid={true}>
            <Row>
                <Col lg="12">
                    <h5>Armor</h5>
                </Col>
            </Row>
            <Row>
                <Col lg="12">           
                    <Table striped>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Mitigation</th>
                            <th>Penalty</th>
                            <th>HP</th>
                            <th>Weight</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                        {
                            (equipmentlist)
                            ?   equipmentlist.map( r => 
                                <tbody key={r.key} >
                                    <tr className="bolder-headings">
                                    <td>{ (r.magicmitigationadj || r.magicarmorpenaltyadj 
                                            || r.magichpadj || r.magicweightadj 
                                            || ( r.magicskills && r.magicskills.length > 0)) 
                                                ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                                : <span>{r.name}, { r.quality }</span>            
                                            }</td>
                                            <td>{ r.mitigation } <span className="soft-blue under-emphasis"> {
                                                (r.magicmitigationadj)
                                                    ? r.magicmitigationadj > 0
                                                    ? '+' + r.magicmitigationadj 
                                                    : r.magicmitigationadj
                                                : null
                                                }</span></td>
                                            <td>{ r.armorpenalty } <span className="soft-blue under-emphasis"> {
                                                (r.magicarmorpenaltyadj)
                                                    ? r.magicarmorpenaltyadj > 0
                                                        ? '+' + r.magicarmorpenaltyadj 
                                                        : r.magicarmorpenaltyadj
                                                    : null
                                                    }</span></td>
                                            <td>{ r.hp }   
                                                <span className="soft-blue under-emphasis"> {
                                                    (r.magichpadj) 
                                                        ? r.magichpadj > 0 
                                                            ? '+' + r.magichpadj 
                                                            : r.magichpadj
                                                        : null
                                                    }</span></td>
                                            <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                                (r.magicweightadj)
                                                    ? r.magicweightadj > 0 
                                                        ? '+' + r.magicweightadj + 'kg'  
                                                        : r.magicweightadj + 'kg' 
                                                    :null
                                                    }</span></td>
                                            <td>{ r.price } g</td>
                                    </tr>             
                                </tbody>
                                    )
                                : <tr><td>No items...</td></tr>
                            }
                    </Table>             
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                </Col>
            </Row>
        </Container>
        : <div></div>
    }
}

export default Armor;