import { connect } from 'react-redux'
import FieldEquipment from '../../components/Inventory/TypeControls/FieldEquipment'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {  
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldEquipment)