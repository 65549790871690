
import React, { Component } from 'react';
import Template from '../containers/Template'
import TemplateList from '../containers/TemplateList'

class TemplateRoot extends Component {
  async componentDidMount() {
    // const {
    // } = this.props

  }

  render() {
    let destination = this.props.destination


    return (
      <div>     
        {
         (destination) 
         ? <Template />
         : <TemplateList />
        }
      </div>
    );
  }
}

export default TemplateRoot;



