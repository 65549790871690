
import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap'


class StaticPagesList extends Component {
  async componentDidMount() {
   
  }

  changeDestination(key) {
      this.props.changeDestination(key)
  }

  render() {
    let sections = this.props.sections
    let camppagescats = this.props.camppagescats
    let pl = this.props.pagelist
    let cl = this.props.camppages

    return (
      <Container fluid={true}>
        <Row>
            <Col lg="12">
                The Reverie Online is an engine designed to help you turn any story into a game. 
                The rules are simple to learn, reference if necessary, and playing with a group 
                of friends is a blast!
            </Col>
        </Row>
        <Row>
            <Col>
               &nbsp;
            </Col>
        </Row>
        <Row>
            <Col>
                <h3>Resources</h3>
            </Col>
        </Row>
        <Row>
            <Col xs="12" sm="6" md="4" lg="4"><a href="/?p=bookofraces&t=races">The Book of Species/Races</a></Col>
            <Col xs="12" sm="6" md="4" lg="4"><a href="/?p=bookofheritages&t=heritages">The Book of Heritages</a></Col>
            <Col xs="12" sm="6" md="4" lg="4"><a href="/?p=bookofprofessions&t=professions">The Book of Professions</a></Col>
            <Col xs="12" sm="6" md="4" lg="4"><a href="/?p=bookofskills">The Book of Skills</a></Col>
            <Col xs="12" sm="6" md="4" lg="4"><a href="/?p=equipment">The Book of Equipment</a></Col>
            <Col xs="12" sm="6" md="4" lg="4"><a href="/?p=bookofmonsters&t=monsters">The Book of Monsters</a></Col> 
        </Row>
        <Row>
            <Col>
               &nbsp;
            </Col>
        </Row>
        <Row>
            <Col xs="12" sm="6" md="4" lg="4"><a className="tools-cs" href="/charactersheet.html">Character Sheet</a></Col>
        </Row>
        <Row>
            <Col>
                <h3>Core Rules</h3>
            </Col>
        </Row>
        <Row>
              <Col lg="12">
                {
                (pl)
                    ? <Container fluid={true}>
                        <Row>
                            {
                                (sections)
                                    ? sections.map(r => 
                                        <Col xs="12" sm="6" md="4" lg="4" key={r}>
                                            <h4>{ r }</h4>                            
                                            <ul className ="inner-bullet">
                                            {
                                                (pl)
                                                ? pl.filter(f => f.section === r).map(m => 
                                                    <li key={m.topicheader} >
                                                        <a className="fakelink" href={`/?p=pages&v=${'pages|' + m.key}`}>{m.name}</a>
                                                    </li>)
                                                : null
                                            }
                                            </ul>
                                        </Col>)
                                    : null

                            }   
                        </Row>
                    </Container>
                    : <div></div>
                }
            </Col>
        </Row>
        <Row>
            <Col>
               &nbsp;
            </Col>
        </Row>
        <Row>
            <Col>
                <h3>Bitter Watches of the Night</h3>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
            {
                (cl)
                    ? <Container fluid={true}>
                        <Row>
                            {
                                (camppagescats)
                                    ? camppagescats.map(r => 
                                        <Col xs="12" sm="6" md="4" lg="4" key={r}>
                                            <h4>{ r }</h4>                            
                                            <ul className ="inner-bullet">
                                            {
                                                (cl)
                                                ? cl.filter(f => f.section === r).map(m => 
                                                    <li key={m.topicheader} >
                                                        <a className="fakelink" href={`/?p=pages&v=${'campaignsetting|' + m.key}`}>{m.name}</a>
                                                    </li>)
                                                : null
                                            }
                                            </ul>
                                        </Col>)
                                    : null

                            }   
                        </Row>
                    </Container>
                    : <div></div>
                }
            </Col>
        </Row>
      </Container>
    );
  }
}

export default StaticPagesList;



