
import React, { Component } from 'react';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'

class Skill extends Component {
  async componentDidMount() {
    const {
      loadSkill,
      destination,
      loadPageList,
      loadSkillsList
    } = this.props

    Events.scrollEvent.register('begin', function(to, element) {
    });
 
    Events.scrollEvent.register('end', function(to, element) {
    });
 
    scrollSpy.update();

    await loadPageList('pages')
    await loadSkillsList()
    await loadSkill(destination)
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {
    let cs = this.props.currentskill

    let anchortarget = this.props.anchortarget
    let skillslist = this.props.skillslist
    let pagelist = this.props.pagelist
    if (cs && pagelist && skillslist) this.props.hyperlink(cs)

    if (cs && anchortarget) { 
      let elem = document.getElementById("#" + anchortarget.toLowerCase())
      if (elem) {
        let rect = elem.getBoundingClientRect()
        scroll.scrollTo(rect.top);
      }             
    }

    return (
      <div>     
        {
        (cs)
          ? <div>
              <h3 className="extra-large-emphasis">{ cs.name }</h3>
              <div dangerouslySetInnerHTML={{ __html: cs.description }} >
            </div>
            {
              cs.techniques.map(m => 
                <div key={m.key} id={m.anchor}>
                  { m.hastype }
                  <h4 className="large-emphasis">{ m.name } { (m.hastype) ? '(Typed)' : ''  }</h4>
                  <h5 className="no-bottom-margin medium-emphasis"> Opposed by { m.opposedby }</h5>
                  <h5 className="no-top-margin medium-emphasis">{ m.time }</h5>
                    <div dangerouslySetInnerHTML={{ __html: m.description }} >
                    </div>
                </div>
              )
            }
          </div>
          : <div></div>
        }
      </div>
    );
  }
}

export default Skill;



