import { connect } from 'react-redux'
import { 
    unwindGear
} from '../../actions'
import Inventory from '../../components/Inventory/Inventory'

const mapStateToProps = state => {
    return {
        currenttemplate: state.currenttemplate,
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {            
        unwindGear() {
            dispatch(unwindGear())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory)