import { connect } from 'react-redux'
import {
    loadSkillsList,
    loadEquipmentList
} from '../actions'
import EquipmentRoot from '../components/EquipmentRoot';

const mapStateToProps = state => {
    return {
        destination: state.destination,
        skillsloaded: state.skillsloaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadEquipmentList: value => {
            dispatch(loadEquipmentList(value))
        },
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        },
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentRoot)
