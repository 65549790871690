import { connect } from 'react-redux'
import ResourcePagesList from '../components/ResourcePagesList';
import {
    changeDestination
} from '../actions'
const mapStateToProps = state => {
    return {
        taxonomies: state.taxonomies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeDestination(key) {
            dispatch(changeDestination(key))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePagesList)
