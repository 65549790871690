import React, { Component } from 'react';
import { Table } from 'reactstrap'

class EquipmentAmmunition extends Component {
  async componentDidMount() {
    const {
        loadSkillsList
      } = this.props

     await loadSkillsList()
  }

  render() {    
    const equipmentlist = (this.props.equipmentlist) 
                ? this.props.equipmentlist.filter(t => t.type === 'ammunition') 
                : []

        return (
            <div>            
                <h2>Ammunition ({ equipmentlist.length } Items)</h2>
                <p>
                    Projectiles are required for ranged weapons. Those listed here are of sturdy quality. Add the 
                    Range values given in the table below to those of the weapon. Do the same with the Damage values.
                </p>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Damage Type</th>
                                <th>Range Adjustment</th>
                                <th>Size</th>
                                <th>Weight</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                    {
                    (equipmentlist)
                    ?   equipmentlist.map(r => 
                        <tbody key={r.key} className="bottom-border">                                       
                            <tr className="bolder-headings">
                            <td>{ (r.damageadj || r.magicrangeadj || r.magicweightadj) 
                                                ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                                : <span>{r.name}, { r.quality }</span> 
                                            }</td>
                                            <td>{ r.damagetype } <span className="soft-blue under-emphasis"> {
                                                (r.damageadj)
                                                    ? r.damageadj > 0
                                                    ? '+' + r.damageadj 
                                                    : r.damageadj
                                                : null
                                                }</span></td>
                                            <td>{ r.rangeadjustment } <span className="soft-blue under-emphasis"> {
                                                (r.magicrangeadj)
                                                    ? r.magicrangeadj > 0
                                                        ? '+' + r.magicrangeadj 
                                                        : r.magicrangeadj
                                                    : null
                                                    }</span></td>
                                            <td>{ r.size }</td>
                                            <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                                (r.magicweightadj)
                                                    ? r.magicweightadj > 0 
                                                        ? '+' + r.magicweightadj + 'kg'  
                                                        : r.magicweightadj + 'kg' 
                                                    :null
                                                    }</span></td>
                                            <td>{ r.quantity }</td>
                                            <td>{ r.price } g</td>
                            </tr>
                            {
                                            (r.equipmentskills) 
                                            ? <tr className='borderless-row'>
                                                <td  colSpan="14"><span className="middle bolder-headings under-emphasis">Used With:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.equipmentskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                        {
                                            (r.magicskills) 
                                            ? <tr className='borderless-row'>
                                                <td colSpan="14"><span className="middle bolder-headings under-emphasis">Magic Bonuses:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.magicskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                        </tbody>
                    )
                : <tr><td>No items...</td></tr>
                }
            </Table>  
        <div> 
            {
                (equipmentlist)
                ?   equipmentlist.map(r => 
                    <div>
                    <h3>{ r.name }, { r.quality }</h3>
                    <p>
                        { r.description }
                    </p>
                 </div>     
                     )
             : <tr><td>No items...</td></tr>
            }
       
        </div>
    </div>
    );
  }
}

export default EquipmentAmmunition;
