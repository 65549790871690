import c from './constants'

export default (state = {}, { type, payload }) => {
    switch (type) {
        case c.actions.LOAD_NAV_TYPE:

            return {
                ...state,
                navtype: payload.loadtype,
                destination: payload.destination,
                listtype: payload.listtype,
                anchortarget: payload.anchortarget
            }

        case c.actions.LOAD_PAGE_SUCCESS:
            let newPage = payload
            
            if (newPage.topics && newPage.topics.length > 0) {
                newPage.topics.forEach(topic =>  {
                    topic.anchor = "#" + replaceAll(topic.topicheader, " ", "_").toLowerCase()
                    return topic
                })                    
            }

            return {
                ...state,
                page: newPage
            }       
            
        case c.actions.LOAD_PAGE_LIST_SUCCESS:
            let newPagelist = {...state}.pagelist || []
            let newCampPages = {...state}.camppages || []

            if (payload.book === "pages") {
                newPagelist = payload.list.map(x => {
                    x.pagesort = parseInt(x.pagesort)
                    return x
                })
            }

            if (payload.book === "campaignsetting") {
                newCampPages = payload.list.map(x => {
                    x.pagesort = parseInt(x.pagesort)
                    return x
                })
            }
            
            newPagelist = newPagelist.sort(pageSort)
            let pagecats = newPagelist.map(m => m.section).filter(distinct).sort()

            newCampPages = newCampPages.sort(pageSort)
            let camppagescats = newCampPages.map(m => m.section).filter(distinct).sort()

            return {
                ...state,
                sections: pagecats,
                pagelist: newPagelist,
                camppages: newCampPages,
                camppagescats: camppagescats
            } 
                
        case c.actions.LOAD_SKILLS_LIST_SUCCESS: 
            return {
                ...state,
                skillslist: payload.skills
            }

        case c.actions.LOAD_SKILL_SUCCESS:

        let newSkill = payload
            
            if (newSkill.technique && newSkill.technique.length > 0) {
                newSkill.technique.forEach(technique =>  {
                    technique.anchor = "#" + replaceAll(technique.name, " ", "_").toLowerCase()
                    return technique
                })                    
            }

            //TODO: Need a way to hyperlink column headings and row headings

            return {
                ...state,
                currentskill: payload
            }

        case c.actions.LOAD_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                templatelist: payload
            }

        case c.actions.LOAD_TEMPLATE_SUCCESS:
            return {
                ...state,
                currenttemplate: payload
            }

        case c.actions.LOAD_EQUIPMENT_LIST_SUCCESS:
            return {
                ...state,
                equipmentlist: equipmentHyperlinker(payload).sort(sortByName)
            }

        case c.actions.UNWIND_GEAR:
            let currenttemplate = {...state.currenttemplate}

            let gear = (currenttemplate) 
                ? currenttemplate.bags.map(f => f.inventory).flat()
                : []
            
            return {
                ...state,
                equipmentlist: gear
            }

        case c.actions.CHANGE_DESTINATION: 
            return {
                ...state,
                destination: payload
            }

        case c.actions.HYPERLINK: 

            if (payload.type === 'page')
                return {
                    ...state,
                    page: pageHyperlinker(state.pagelist, state.skillslist, payload.value)
                }
            else 
                return {
                    ...state,
                    currentskill: skillHyperlinker(state.pagelist, state.skillslist, payload.value)
                }

        case c.actions.EQUIPMENT_HYPERLINK:
                return {
                    ...state,
                    equipmentlist: equipmentHyperlinker(
                        state.pagelist,
                        state.skillslist,
                        state.equipmentlist)
                }

        case c.actions.LOAD_TAXONOMY_SUCCESSFUL:
                return {
                    ...state,
                    taxonomies: payload.taxonomies.sort(sortByHeading)
                }

        case c.actions.HYPERLINK_TEMPLATE_TAXONOMY: 
                return {
                    ...state,
                    currenttemplate: hyperlinkTemplate(payload, state.taxonomies)
                }

        default:
            return {
                ...state,
                foci: buildFoci(),
                realms: buildRealms()
            }
    }
}

const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
}

const pageSort = (a, b) => {
    var A = a.pagesort;
    var B = b.pagesort;
    return (A < B) ? -1 : (A > B) ? 1 : 0;
    }

const sortByName = (a, b) => {
    var A = a.name;
    var B = b.name;
    return (A < B) ? -1 : (A > B) ? 1 : 0;
    }
    
const sortByHeading = (a, b) => {
    var A = a.heading;
    var B = b.heading;
    return (A < B) ? -1 : (A > B) ? 1 : 0;
    }

const buildFoci = () => {
    return [
        { key: "focus-body", name: "Body", score: 0 },
        { key: "focus-mind", name: "Mind", score: 0 },
        { key: "focus-spirit", name: "Spirit", score: 0 }
    ]
}

const distinct = (value, index, self) => {
    return self.indexOf(value) === index
}

const buildRealms = () => {
    return [
        { key: "realm-artistic-abilities", name: "Artistic Abilities", focuskey: "focus-mind" },
        { key: "realm-body-spirit-magic", name: "Body Spirit Magic", focuskey: "focus-spirit" },
        { key: "realm-charm-magic", name: "Charm Magic", focuskey: "focus-spirit"  },
        { key: "realm-combat-tactics", name: "Combat Tactics", focuskey: "focus-mind" }, 
        { key: "realm-energy-magic", name: "Energy Magic", focuskey: "focus-spirit"  },
        { key: "realm-illusion-divination-magic", name: "Illusion and Divination Magic", focuskey: "focus-spirit"  },
        { key: "realm-melee-combat-skills", name: "Melee Combat Skills", focuskey: "focus-body"  },
        { key: "realm-mental-abilities", name: "Mental Abilities", focuskey: "focus-mind"  },
        { key: "realm-mental-skills", name: "Mental Skills", focuskey: "focus-mind"  },
        { key: "realm-nature-magic", name: "Nature Magic", focuskey: "focus-spirit"  },
        { key: "realm-physical-abilities", name: "Physical Abilities", focuskey: "focus-body"  },
        { key: "realm-physical-skills", name: "Physical Skills", focuskey: "focus-body"  },
        { key: "realm-planar-magic", name: "Planar Magic", focuskey: "focus-spirit"  },
        { key: "realm-ranged-combat-skills", name: "Ranged Combat Skills", focuskey: "focus-body"  },
        { key: "realm-social-abilities", name: "Social Abilities", focuskey: "focus-mind"  },
        { key: "realm-space-time-magic", name: "Space-Time Magic", focuskey: "focus-spirit"  },
        { key: "realm-transmogrification-magic", name: "Transmogrification Magic", focuskey: "focus-spirit"  }
    ]
}

const pageHyperlinker = (pagelist, skillslist, page) => {
    if (!pagelist || !skillslist) return page

    pagelist.forEach(f =>  {
        if (page.introduction.indexOf(f.name) > -1) {         
            if (page.introduction.substring(page.introduction.indexOf(f.name)-10, 10).indexOf("=") === -1) {
                page.introduction = page.introduction.replace(f.name, `<a href="/?p=pages&v=${'pages|'+f.key}">${f.name}</a>` )  
            }
        }
        if (f.topics.length > 0) {
            // for each topic on this page
            f.topics.forEach(t => {
                if (page.introduction.indexOf(t.topicheader) > -1) {
                    // looking to see if this is already in a link, skip it if it is.
                    if (page.introduction.substring(page.introduction.indexOf(t.topicheader)-10, 10).indexOf("=") === -1) {
                        page.introduction = page.introduction.replace(t.topicheader, `<a href="/?p=pages&v=${'pages|'+f.key}&h=${replaceAll(t.topicheader, " ", "_").toLowerCase()}">${t.topicheader}</a>`)
                    }
                }

                if (page.topics.length > 0) {
                    page.topics.forEach(pt => {
                        if (pt.body.indexOf(t.topicheader) > -1) {
                            if (pt.body.substr(pt.body.indexOf(t.topicheader)-5, 10).indexOf("=") === -1) {
                                pt.body = pt.body.replace(t.topicheader, `<a href="/?p=pages&v=${'pages|'+f.key}&h=${replaceAll(t.topicheader, " ", "_").toLowerCase()}">${t.topicheader}</a>`)
                            }
                        }

                        if (page.introduction.indexOf(t.topicheader) > -1) {            
                            if (page.introduction.substr(page.introduction.indexOf(t.topicheader)-5, 10).indexOf("=") === -1)
                                page.introduction = page.introduction = page.introduction.replace(t.topicheader, `<a href="/?p=pages&v=${'pages|'+f.key}&h=${replaceAll(t.topicheader, " ", "_").toLowerCase()}">${t.topicheader}</a>` ) 
                        }
                    })
                }
            })
        }
    })

    for (var i = 0; i < skillslist.length; i++) {
        if (page.introduction.indexOf(skillslist[i].name) > -1) {
            page.introduction = page.introduction.replace(skillslist[i].name, `<a href="/?p=bookofskills&v=${skillslist[i].key}">${skillslist[i].name}</a>`)
        }

        if (page.topics.length > 0) {            
            page.topics.forEach(pt => {  // eslint-disable-line no-loop-func
                if (pt.body.indexOf(skillslist[i].name) > -1) {
                    pt.body = pt.body.replace(skillslist[i].name, `<a href="/?p=bookofskills&v=${skillslist[i].key}">${skillslist[i].name}</a>`)
                }
                return pt
            })
        }
    }

    skillslist.forEach(s =>  {
        s.techniques.forEach(t => {
            if (page.introduction.indexOf(t.name) > -1) {
                if (page.introduction.substr(page.introduction.indexOf(t.topicheader)-5, 10).indexOf("=") === -1) {
                    page.introduction = page.introduction.replace(t.name, `<a href="/?p=bookofskills&v=${s.key}">${t.name}</a>`)
                }
            }

            if (page.topics.length > 0) {
                page.topics.forEach(pt => {
                    if (pt.body.indexOf(t.name) > -1) {
                        if (pt.body.substr(pt.body.indexOf(t.topicheader)-5, 10).indexOf("=") === -1) {
                        pt.body = pt.body.replace(t.name, `<a href="/?p=bookofskills&v=${s.key}&h=${replaceAll(t.name, " ", "_").toLowerCase()}">${t.name}</a>`)
                        }
                    }
                })
            }
        })
    })

    return page
}

const skillHyperlinker = (pagelist, skillslist, skill) => {
    if (!pagelist || !skillslist) return skill

    pagelist.forEach(f =>  {
        if (skill.description.indexOf(f.name) > -1) {        
            if (skill.description.substr(skill.description.indexOf(f.name)-5, 10).indexOf("=") === -1) {
                skill.description = skill.description.replace(f.name, `<a href="/?p=pages&v=${'pages|'+f.key}">${f.name}</a>` )  
            }
        }
        if (f.topics.length > 0) {
            f.topics.forEach(t => {
                if (skill.description.indexOf(t.topicheader) > -1) {
                    if (skill.description.substr(skill.description.indexOf(t.topicheader)-5, 10).indexOf("=") === -1) {
                        skill.description = skill.description.replace(t.topicheader, `<a href="/?p=pages&v=${'pages|'+f.key}&h=${replaceAll(t.topicheader, " ", "_").toLowerCase()}">${t.topicheader}</a>`)
                    }
                }

                if (skill.techniques.length > 0) {
                    skill.techniques.forEach(pt => {
                        if (pt.description.indexOf(t.topicheader) > -1) {
                            if (pt.description.substr(pt.description.indexOf(t.topicheader)-5, 10).indexOf("=") === -1) {
                                pt.description = pt.description.replace(t.topicheader, `<a href="/?p=pages&v=${'pages|'+f.key}&h=${replaceAll(t.topicheader, " ", "_").toLowerCase()}">${t.topicheader}</a>`)
                            }
                        }

                        if (skill.description.indexOf(t.topicheader) > -1) {            
                            if (skill.description.substr(skill.description.indexOf(t.topicheader)-5, 10).indexOf("=") === -1)
                            skill.description  = skill.description.replace(t.topicheader, `<a href="/?p=pages&v=${'pages|'+f.key}&h=${replaceAll(t.topicheader, " ", "_").toLowerCase()}">${t.topicheader}</a>` ) 
                        }
                    })
                }
            })
        }
    })

    // for (var i = 0; i < skillslist.length; i++) {
    //     if (page.introduction.indexOf(skillslist[i].name) > -1) {
    //         page.introduction = page.introduction.replace(skillslist[i].name, `<a href="/?p=bookofskills&v=${skillslist[i].key}">${skillslist[i].name}</a>`)
    //     }

    //     if (page.topics.length > 0) {            
    //         page.topics.forEach(pt => {  // eslint-disable-line no-loop-func
    //             if (pt.body.indexOf(skillslist[i].name) > -1) {
    //                 pt.body = pt.body.replace(skillslist[i].name, `<a href="/?p=bookofskills&v=${skillslist[i].key}">${skillslist[i].name}</a>`)
    //             }
    //             return pt
    //         })
    //     }
    // }

    // skillslist.forEach(s =>  {
    //     s.techniques.forEach(t => {
    //         if (page.introduction.indexOf(t.name) > -1) {
    //             if (page.introduction.substring(page.introduction.indexOf(t.topicheader)-10, 10).indexOf("=") === -1) {
    //                 page.introduction = page.introduction.replace(t.name, `<a href="/?p=bookofskills&v=${s.key}">${t.name}</a>`)
    //             }
    //         }

    //         if (page.topics.length > 0) {
    //             page.topics.forEach(pt => {
    //                 if (pt.body.indexOf(t.name) > -1) {
    //                     if (pt.body.substring(pt.body.indexOf(t.topicheader)-10, 10).indexOf("=") === -1) {
    //                     pt.body = pt.body.replace(t.name, `<a href="/?p=bookofskills&v=${s.key}&h=${replaceAll(t.name, " ", "_").toLowerCase()}">${t.name}</a>`)
    //                     }
    //                 }
    //             })
    //         }
    //     })
    // })

    return skill
}

const equipmentHyperlinker = (equipmentlist) => {
    equipmentlist.forEach(e => {
        if (e.magicskills && e.magicskills.length > 0) {
            e.magicskills.forEach(ms => {
                ms.hyperlink = `<a href="/?p=bookofskills&v=${ms.skillkey}&h=${replaceAll(ms.techname, " ", "_").toLowerCase()}">${ms.techname + " of " + ms.skillname}</a>`
            })
        }

        if (e.equipmentskills && e.equipmentskills.length > 0) {
            e.equipmentskills.forEach(ms => {
                ms.hyperlink = `<a href="/?p=bookofskills&v=${ms.skillkey}&h=${replaceAll(ms.techname, " ", "_").toLowerCase()}">${ms.techname + " of " + ms.skillname}</a>`
            })
        }
    })

    return equipmentlist
}

const hyperlinkTemplate = (template, taxonomy) => {
    if (template.race === undefined || template.race === '') return template
    if (!template.textsections || template.textsections.length === 0) return template
    if (template.textsections.filter(ts => ts.title === "Taxonomy")[0] === undefined) return template
    
    let taxonomySection = template.textsections.filter(ts => ts.title === "Taxonomy")[0]
    taxonomy.forEach(tax => {
        taxonomySection.body = taxonomySection.body.replace(tax.heading, `<a target="_blank" href="/?p=resources&v=taxonomy&h=${tax.heading}">${tax.heading}</a>` ) 
    })

    return template
}