import { connect } from 'react-redux'

import EquipmentClothing from '../components/EquipmentClothing';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
     
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentClothing)
