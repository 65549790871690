
import React, { Component } from 'react';
import StaticPages from '../containers/StaticPages'
import StaticPagesList from '../containers/StaticPagesList'

class StaticPagesRoot extends Component {
  async componentDidMount() {
    const {
      loadPageList,
      loadSkillsList
    } = this.props

    await loadPageList('pages')
    await loadPageList('campaignsetting')
    await loadSkillsList()
  }

  render() {
    let destination = this.props.destination
    return (
      <div>     
        {
         (destination !== 'home') 
         ? <StaticPages />
         : <StaticPagesList />
        }
      </div>
    );
  }
}

export default StaticPagesRoot;



