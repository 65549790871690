import { connect } from 'react-redux'
import Clothing from '../../components/Inventory/TypeControls/Clothing'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return { 

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clothing)