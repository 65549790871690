import { connect } from 'react-redux'

import EquipmentWorkingAnimals from '../components/EquipmentWorkingAnimals';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
     
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentWorkingAnimals)
