import React from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import './index.css';
import Root from './containers/Root';
import configureStore from './configureStore'

const store = configureStore()

render(
    <Provider store={store}>
            <Root />
    </Provider>,
    document.getElementById('root')
)
