import { connect } from 'react-redux'


import Ammunition from '../../components/Inventory/TypeControls/Ammunition'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {

}

export default connect(mapStateToProps, mapDispatchToProps)(Ammunition)