
import React, { Component } from 'react';
import ResourcePages from '../containers/ResourcePages'
import ResourcePagesList from '../containers/ResourcePagesList'

class ResourcePagesRoot extends Component {
  async componentDidMount() {
    const {
      loadTaxonomy
    } = this.props

    await loadTaxonomy()
  }

  render() {
    let destination = this.props.destination
    
    return (
      <div>     
        {
         (destination) 
         ? <ResourcePages />
         : <ResourcePagesList />
        }
      </div>
    );
  }
}

export default ResourcePagesRoot;



