import { fork } from 'redux-saga/lib/effects'
import { watchLoadPageData, watchLoadPageListData } from './page'
import { 
  watchLoadSkillListsData, 
  watchLoadSingleSkillData
} from './skills'
import { 
  watchLoadTemplateListData,
  watchLoadTemplateData 
} from './templates'
import { 
  watchLoadEquipmentListData
} from './equipment'
import {
  watchLoadTaxonomyData
} from './resource'

//entry point to start all sagas at once
export default function* rootSaga() {
  yield [
   fork(watchLoadPageData),
   fork(watchLoadPageListData),
   fork(watchLoadSkillListsData),
   fork(watchLoadSingleSkillData),
   fork(watchLoadTemplateListData),
   fork(watchLoadTemplateData),
   fork(watchLoadEquipmentListData),
   fork(watchLoadTaxonomyData)
  ]
}