import React, { Component } from 'react';
import { Table } from 'reactstrap'

class EquipmentShields extends Component {
  async componentDidMount() {
   
  }

  render() {    
    const equipmentlist = (this.props.equipmentlist) 
                ? this.props.equipmentlist.filter(t => t.type === 'shields') 
                : []

    return (
        <div>                    
            <h2>Shields ({ equipmentlist.length } Items)</h2>
            <p>
                Shields provide protection and cover.
            </p>
            <ul>
                <li><strong>Mitigation</strong> tells you how much damage you can ignore if successfully attacked.</li>
                <li><strong>Penalty</strong> tells you how much certain skills suffer when you were this type of shield.</li>
                <li><strong>Hit Points</strong> tells you how much damage the piece of shield itself can withstand before being destroyed. Equipment only 
                takes damage, normally, when the equipment and not you personally is the target of the attack.</li>
            </ul>
            <p>
                Not all shields listed here may be available in all campaigns.  
            </p>
            <div className="row">
            <Table striped>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Mitigation</th>
                                <th>Penalty</th>
                                <th>HP</th>
                                <th>Size</th>
                                <th>Weight</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                            {
                                (equipmentlist)
                                ?   equipmentlist.map( r => 
                                    <tbody key={r.key} >
                                        <tr className="bolder-headings">
                                        <td>{ (r.magicmitigationadj || r.magicarmorpenaltyadj || r.magichpadj || r.magicweightadj) 
                                                ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                                : <span>{r.name}, { r.quality }</span>            
                                            }</td>
                                            <td>{ r.mitigation } <span className="soft-blue under-emphasis"> {
                                                (r.magicmitigationadj)
                                                    ? r.magicmitigationadj > 0
                                                    ? '+' + r.magicmitigationadj 
                                                    : r.magicmitigationadj
                                                : null
                                                }</span></td>
                                            <td>{ r.armorpenalty } <span className="soft-blue under-emphasis"> {
                                                (r.magicarmorpenaltyadj)
                                                    ? r.magicarmorpenaltyadj > 0
                                                        ? '+' + r.magicarmorpenaltyadj 
                                                        : r.magicarmorpenaltyadj
                                                    : null
                                                    }</span></td>
                                            <td>{ r.hp }   
                                                <span className="soft-blue under-emphasis"> {
                                                    (r.magichpadj) 
                                                        ? r.magichpadj > 0 
                                                            ? '+' + r.magichpadj 
                                                            : r.magichpadj
                                                        : null
                                                    }</span></td>
                                            <td>{ r.size }</td>
                                            <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                                (r.magicweightadj)
                                                    ? r.magicweightadj > 0 
                                                        ? '+' + r.magicweightadj + 'kg'  
                                                        : r.magicweightadj + 'kg' 
                                                    :null
                                                    }</span></td>
                                            <td>{ r.price } g</td>
                                        </tr> 
                                        {
                                            (r.equipmentskills) 
                                            ? <tr className='borderless-row'>
                                                <td  colSpan="14"><span className="middle bolder-headings under-emphasis">Used With:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.equipmentskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                        {
                                            (r.magicskills) 
                                            ? <tr className='borderless-row'>
                                                <td colSpan="14"><span className="middle bolder-headings under-emphasis">Magic Bonuses:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.magicskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                    </tbody>
                                        )
                                    : <tr><td>No items...</td></tr>
                            }
                    </Table>
                <div> {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <div>
                               <h3>{ r.name }, { r.quality }</h3>
                               <p>
                               { r.description }
                               </p>
                            </div>     
                                )
                        : <tr><td>No items...</td></tr>
                    }
                </div>
            </div>
      </div>
    );
  }
}

export default EquipmentShields;



