import { connect } from 'react-redux'
import MagicItems from '../../components/Inventory/TypeControls/MagicItems'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {  

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicItems)