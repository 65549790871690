
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'

class ResourcePages extends Component {
  async componentDidMount() {
    const {
      } = this.props

      Events.scrollEvent.register('begin', function(to, element) {
      });
   
      Events.scrollEvent.register('end', function(to, element) {
      });
   
      scrollSpy.update();
  
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  changeDestination(key) {
    this.props.changeDestination(key)
  }

  render() {
    let taxonomies = this.props.taxonomies
    let anchortarget = this.props.anchortarget

    if (taxonomies && anchortarget) { 
      let elem = document.getElementById("#" + anchortarget.toLowerCase())
      if (elem) {
        let rect = elem.getBoundingClientRect()
        scroll.scrollTo(rect.top);
      }             
    }

    return (
      <Container fluid={true}>  
          {
          (taxonomies) 
          ? taxonomies.map(m => <Row key={m.heading} id={m.heading.toLowerCase()}>
            <Col>
              <h4>{m.heading}</h4>
              <div className="ident2" dangerouslySetInnerHTML={{ __html: m.body }}></div>
            </Col>
          </Row>)
          : null
          }
      </Container>
    );
  }
}

export default ResourcePages;



