import { connect } from 'react-redux'
import { 
    loadSkillsList
} from '../actions'
import SkillsList from '../components/SkillsList';

const mapStateToProps = state => {
    return {
        realms: state.realms,
        skillslist: state.skillslist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillsList)
