import { call, put, takeEvery } from 'redux-saga/lib/effects'
import c from '../constants'
import { loadEquipmentListSuccess } from '../actions'
import { loadEquipmentListData } from '../api'

export function* watchLoadEquipmentListAsync(action) {
    try {
      let data = yield call(loadEquipmentListData, action.payload)

      yield put(loadEquipmentListSuccess(data))
      } catch(e) {
        console.error(e);
      }
    }

export function* watchLoadEquipmentListData(action) {
    yield takeEvery (
        c.actions.LOAD_EQUIPMENT_LIST, 
        watchLoadEquipmentListAsync
      )
  }