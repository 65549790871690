
import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap'


class SkillsList extends Component {
  async componentDidMount() {
   
  }

  render() {
    
    let realms = this.props.realms
    let sl = this.props.skillslist

    return (
      <Container fluid={true}>
          <Row>
            <h3>List of Skills</h3>
          </Row>
          <Row>
              <Col>
                {
                (sl)
                    ? <Container fluid={true}>
                        <Row>
                        {
                            (realms)
                                ? realms.map(r => 
                                    <Col xs="12" sm="6" md="4" lg="3" key={r.key}>
                                        <h4>{ r.name }</h4>                            
                                        <ul className ="inner-bullet">
                                        {
                                            (sl)
                                            ? sl.filter(f => f.realmkey === r.key).map(m => <li className="fakelink2" key={m.key} ><a href={`?p=bookofskills&v=${m.key}`}>{ m.name }</a></li>)
                                            : null
                                        }
                                    </ul>
                                    </Col>)
                                : <div></div>

                        }   
                        </Row>
                    </Container>
                    : <div></div>
                }
            </Col>
        </Row>
      </Container>
    );
  }
}

export default SkillsList;



