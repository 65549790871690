import { connect } from 'react-redux'
import { 
    loadTemplate,
    loadTaxonomy,
    hyperlinkTemplate
} from '../actions'

import Template from '../components/Template';

const mapStateToProps = state => {
    return {
        listtype: state.listtype,
        destination: state.destination,
        currenttemplate: state.currenttemplate,
        taxonomies: state.taxonomies
    }
}

const mapDispatchToProps = dispatch => {
    return {
       loadTemplate(listype, value) {
            dispatch(loadTemplate(listype, value))
       },
       loadTaxonomy() {
           dispatch(loadTaxonomy())
       },
       hyperlinkTemplate(template) {
            dispatch(hyperlinkTemplate(template))
       }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(Template)
