import React, { Component } from 'react';
import { Table } from 'reactstrap'

class EquipmentDrugsPoisons extends Component {
  async componentDidMount() {
   
  }

  render() {    
    const equipmentlist = (this.props.equipmentlist) 
    ? this.props.equipmentlist.filter(t => t.type === 'drugspoisonillicit') 
    : []

    return (
        <div>                    
            <h2>Drugs, Poisons, and Illicit Goods ({ equipmentlist.length } Items)</h2>
            <p>

            </p>
            <div className="row">
            <Table striped>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Delivery</th>
                        <th>Onset</th>
                        <th>Damage</th>
                        <th>Damage Limit</th>
                        <th>Weight</th>
                        <th>Quantity</th>
                        <th>Price</th>
                    </tr>
                </thead>
                        {
                            (equipmentlist)
                            ?   equipmentlist.map( r => 
                                <tbody key={r.key} className="bottom-border">                                       
                                    <tr className="bolder-headings">
                                        <td>{ r.name }, { r.quality }</td>
                                        <td>{ r.deliverymethod }</td> 
                                        <td>{ r.onset }</td>
                                        <td>{ r.damage }</td>
                                        <td>{ r.damagelimit }</td>
                                        <td>{ r.weight }</td>
                                        <td>{ r.quantity }</td>
                                        <td>{ r.price } g</td>
                                    </tr> 
                                    {
                                            (r.equipmentskills) 
                                            ? <tr className='borderless-row'>
                                                <td  colSpan="10"><span className="middle bolder-headings under-emphasis">Use With:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.equipmentskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                        {
                                            (r.magicskills) 
                                            ? <tr className='borderless-row'>
                                                <td colSpan="10"><span className="middle bolder-headings under-emphasis">Magic Skills:</span>&nbsp;&nbsp;
                                                <span dangerouslySetInnerHTML={{ __html:  r.magicskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                                :null
                                        }
                                </tbody>
                                    )
                                : <tr><td>No items...</td></tr>
                        }
                </Table>                            
                <div> {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <div>
                               <h3>{ r.name }, { r.quality }</h3>
                               <p>
                               { r.description }
                               </p>
                            </div>     
                                )
                        : <tr><td>No items...</td></tr>
                    }
                </div>
            </div>
      </div>
    );
  }
}

export default EquipmentDrugsPoisons;



