import { connect } from 'react-redux'

import EquipmentArmor from '../components/EquipmentArmor';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
     
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentArmor)
