import { connect } from 'react-redux'
import StaticPagesList from '../components/StaticPagesList';
import {
    changeDestination
} from '../actions'
const mapStateToProps = state => {
    return {
        sections: state.sections,
        pagelist: state.pagelist,
        camppagescats: state.camppagescats,
        camppages: state.camppages
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeDestination(key) {
            dispatch(changeDestination(key))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPagesList)
