
import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap'

import Ammunition from '../containers/EquipmentAmmunition'
import Armor from '../containers/EquipmentArmor'
import Clothing from '../containers/EquipmentClothing'
import Consumables from '../containers/EquipmentConsumables'
import Containers from '../containers/EquipmentContainers'
import DrugsPoison from '../containers/EquipmentDrugsPoisons'
import Field from '../containers/EquipmentField'
import HouseholdGoods from '../containers/EquipmentHouseholdGoods'
import LodgingServices from '../containers/EquipmentLodgingServices'
import MagicItems from '../containers/EquipmentMagicItems'
import Shields from '../containers/EquipmentShields'
import Weapons from '../containers/EquipmentWeapons'
import WorkingAnimals from '../containers/EquipmentWorkingAnimals'

class EquipmentRoot extends Component {
  async componentDidMount() {
    const {
        destination,
        loadSkillsList,
        loadEquipmentList
    } = this.props

    await loadSkillsList()
    await loadEquipmentList(destination)
  }

  render() {
    let destination = this.props.destination

    return (
      <div>     
        {
          (!destination)
          ? <Container fluid={true}>
          <Row>
            <Col>
              <h2>Equipment Types</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="/?p=equipment&v=ammunition">Ammunition</a><br />
              <a href="/?p=equipment&v=armor">Armor</a><br />
              <a href="/?p=equipment&v=clothing">Clothing and Jewelry</a><br />
              <a href="/?p=equipment&v=consumables">Consumables</a><br />
              <a href="/?p=equipment&v=containers">Containers</a><br />
              <a href="/?p=equipment&v=drugspoisonillicit">Drugs, Poisons, Elicit Goods</a><br />
              <a href="/?p=equipment&v=fieldequipment">Field Equipment</a><br />
              <a href="/?p=equipment&v=householdsupplies">Household Supplies</a><br />
              <a href="/?p=equipment&v=lodgingservices">Lodging, Travel, and  Services</a><br />
              <a href="/?p=equipment&v=magicitems">Magic Items</a><br />
              <a href="/?p=equipment&v=shields">Shields</a><br />
              <a href="/?p=equipment&v=weapons">Weapons</a><br />
              <a href="/?p=equipment&v=workinganimals">Pets and Working Animals</a><br />
            </Col>
          </Row>
          <Row>
            <Col>
            &nbsp;
            </Col>
          </Row>
          <Row>
            <Col>
            Don't see what you need? Make it yourself at&nbsp;
            <a href="/equipmentcreator.html">The Equipment Store</a>
            </Col>
          </Row>
          </Container>
          : {
            'ammunition': <Ammunition />,
            'armor': <Armor />,
            'clothing': <Clothing />,
            'consumables': <Consumables />,
            'containers': <Containers />,
            'drugspoisonillicit': <DrugsPoison />,
            'fieldequipment': <Field />,
            'householdsupplies': <HouseholdGoods />,
            'lodgingservices': <LodgingServices />,
            'magicitems': <MagicItems />,
            'shields': <Shields />,
            'weapons': <Weapons />,
            'workinganimals': <WorkingAnimals />,
          }[destination]
        }
      </div>
    );
  }
}

export default EquipmentRoot;
