import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './TypeControl.css'
import { Container, Row, Col, Table } from 'reactstrap'

class Consumables extends Component {
  
    async componentDidMount() {

    }

    takeEquipment(key) {
        this.props.takeEquipment(key)
    }

    buyEquipment(key) {
        this.props.buyEquipment(key)
    }

    render() {
        const equipmentlist = this.props.equipmentlist 
            ? this.props.equipmentlist.filter(t => t.type === 'consumables') 
            : []

        return (equipmentlist && equipmentlist.length > 0) 
        ?  <Container fluid={true}>
            <Row>
                <Col lg="12">
                    <h5>Consumables</h5>
                </Col>
            </Row>
            <Row>
                <Col lg="12"> 
               
                </Col>
            </Row> 
            <Row>
                <Col lg="12">
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Size</th>
                            <th>Weight</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                        {
                            (equipmentlist)
                            ?   equipmentlist.map( r => 
                                <tbody key={r.key} className="bottom-border">                                       
                                    <tr className="bolder-headings">
                                        <td>{ (r.magicweightadj || (r.magicskills && r.magicskills.length > 0)) 
                                            ? <span className="soft-blue">{r.name}, { r.quality }</span> 
                                            : <span>{r.name}, { r.quality }</span> 
                                        }</td>
                                        <td>{ r.size }</td>
                                        <td>{ r.quantity } { r.quantityunit }</td>
                                        <td>{ r.weight } kg <span className="soft-blue under-emphasis"> {
                                            (r.magicweightadj)
                                                ? r.magicweightadj > 0 
                                                    ? '+' + r.magicweightadj + 'kg'  
                                                    : r.magicweightadj + 'kg' 
                                                :null
                                                }</span></td>
                                        <td>{ r.price } g</td>
                                    </tr>    
                                </tbody>
                                    )
                                : <tr><td>No items...</td></tr>
                        }
                </Table>                    
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                </Col>
            </Row>
        </Container>
        : <div></div>
    }
}

export default Consumables;