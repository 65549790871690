import React, { Component } from 'react'
import './Inventory.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'reactstrap'
import Ammunition from '../../containers/Inventory/Ammunition'
import Armor from '../../containers/Inventory/Armor'
import Clothing from '../../containers/Inventory/Clothing'
import Consumables from '../../containers/Inventory/Consumables'
import Containers from '../../containers/Inventory/Containers'
import DrugsPoisonIllicit from '../../containers/Inventory/DrugsPoisonIllicit'
import FieldEquipment from '../../containers/Inventory/FieldEquipment'
import HouseholdSupplies from '../../containers/Inventory/HouseholdSupplies'
import LodgingServices from '../../containers/Inventory/LodgingServices'
import MagicItems from '../../containers/Inventory/MagicItems'
import Shields from '../../containers/Inventory/Shields'
import Weapons from '../../containers/Inventory/Weapons'
import WorkingAnimals from '../../containers/Inventory/WorkingAnimals'

class Inventory extends Component {
  
    async componentDidMount() {
        const {
            currenttemplate,
            unwindGear
          } = this.props
      
          await unwindGear(currenttemplate)
     }


    render() {
        const equipmentlist = this.props.equipmentlist
        return  (equipmentlist) 
            ? <Container fluid={true}>       
                    <Row>
                        <Col md="12">                 
                            <h2>Items Carried</h2>   
                        </Col> 
                    </Row>          
                    <Row>               
                        <Col lg="12">  
                            <Ammunition />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Armor />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Clothing />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Consumables />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Containers />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <DrugsPoisonIllicit />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FieldEquipment />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <HouseholdSupplies />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <LodgingServices />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <MagicItems />
                        </Col>
                    </Row> 
                    <Row>
                        <Col lg="12">
                            <Shields />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Weapons />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <WorkingAnimals />
                        </Col>
                    </Row>
                </Container>
        : null
    }
}

export default Inventory;