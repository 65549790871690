
import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap'
import uuid4 from 'uuid4'

class TemplateList extends Component {
  async componentDidMount() {
    const {
        listtype,
        loadTemplateList
      } = this.props
  
      await loadTemplateList(listtype)
  }

  render() {
    
    let temp = this.props.templatelist
    let listype = this.props.listtype

    return ( (temp)
                ? <Container fluid={true}>    
                    <Row className="listwrapper">
                        <Col>
                            <h2>List of {temp.templatetitle}</h2>
                        </Col>
                    </Row>
                    {
                        temp.mastercategories.map(s =>  
                            <Container fluid={true} key={uuid4()}>
                                <Row>
                                    <Col><h4>{s.mastercategorytitle}</h4></Col>
                                </Row>
                                <Row key={s.key}>
                                    <Col>
                                    {
                                        <Container fluid={true}>
                                            <Row>
                                                <Col></Col>
                                            </Row>
                                            <Row>
                                                { 
                                                    s.templatecategories.map(r => 
                                                        <Col xs= "12" sm="6" md="4" lg="3" className="extended-padding" key={uuid4()}>
                                                            <h5 className="extended-padding"> { r.categoryname } </h5>                                               
                                                            <ul>
                                                            {
                                                                r.templates.map(m => 
                                                                <li key={m.value}><a href={`?p=bookof${listype}&v=${m.value}&t=${listype}`}>{ m.name }</a></li> 
                                                                )
                                                            }
                                                            </ul>
                                                        </Col> 
                                                    )
                                                }
                                            </Row>
                                        </Container>
                                    }
                                    </Col>
                                </Row>
                            </Container>)
                    }
                </Container>
                : null
            // : <Container fluid={true}>
            //         <Row>
            //         <Col>
            //         <h2>Challenges</h2>
            //         </Col>
            //     </Row>
            //     <Row>
            //         <Col>
                   
            //         {/* <a href="/?p=bookofchallenges&v=armor">Armor</a><br />
            //         <a href="/?p=bookofchallenges&v=clothing">Clothing and Jewelry</a><br />
            //         <a href="/?p=bookofchallenges&v=consumables">Consumables</a><br />
            //         <a href="/?p=equbookofchallengesipment&v=containers">Containers</a><br />
            //         <a href="/?p=bookofchallenges&v=drugspoisonillicit">Drugs, Poisons, Elicit Goods</a><br />
            //         <a href="/?p=bookofchallenges&v=fieldequipment">Field Equipment</a><br />
            //         <a href="/?p=bookofchallenges&v=householdsupplies">Household Supplies</a><br />
            //         <a href="/?p=bookofchallenges&v=lodgingservices">Lodging, Travel, and  Services</a><br />
            //         <a href="/?p=bookofchallenges&v=magicitems">Magic Items</a><br />
            //         <a href="/?p=bookofchallenges&v=shields">Shields</a><br />
            //         <a href="/?p=bookofchallenges&v=weapons">Weapons</a><br />
            //         <a href="/?p=bookofchallenges&v=workinganimals">Pets and Working Animals</a><br /> */}
            //         </Col>
            //     </Row>
            // </Container>
    );
  }
}

export default TemplateList;



