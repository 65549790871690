import { connect } from 'react-redux'

import EquipmentLodgingServices from '../components/EquipmentLodgingServices';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
     
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLodgingServices)
