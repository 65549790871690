import { call, put, takeEvery } from 'redux-saga/lib/effects'
import c from '../constants'
import { loadSkillsListSuccess, loadSkillSuccess } from '../actions'
import { loadSkillsListData, loadSkillData } from '../api'

export function* watchLoadSkillsListAsync(action) {
    try {

      let data = yield call(loadSkillsListData)

      yield put(loadSkillsListSuccess(data))
      } catch(e) {
        console.error(e);
      }
    }

export function* watchLoadSkillListsData(action) {
    yield takeEvery (
        c.actions.LOAD_SKILLS_LIST, 
        watchLoadSkillsListAsync
      )
  }

export function* watchLoadSingleSkillDataAsync(action) {
  try {

    let data = yield call(loadSkillData, action.payload)

    yield put(loadSkillSuccess(data))
    } catch(e) {
      console.error(e);
    }
}

export function* watchLoadSingleSkillData(action) {
  yield takeEvery (
      c.actions.LOAD_SKILL, 
      watchLoadSingleSkillDataAsync
    )
}