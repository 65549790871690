import { connect } from 'react-redux'

import EquipmentConsumables from '../components/EquipmentConsumables';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
     
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentConsumables)
