import { call, put, takeEvery } from 'redux-saga/lib/effects'
import c from '../constants'
import { loadTaxonomySuccessful } from '../actions'
import { loadTaxonomyData } from '../api'

export function* watchLoadTaxonomyAsync(action) {
    try {
      let data = yield call(loadTaxonomyData)

      yield put(loadTaxonomySuccessful(data))
      } catch(e) {
        console.error(e);
      }
  }

export function* watchLoadTaxonomyData(action) {
    yield takeEvery (
        c.actions.LOAD_TAXONOMY, 
        watchLoadTaxonomyAsync
      )
  }