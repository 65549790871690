import React, { Component } from 'react';
import { Table } from 'reactstrap'

class EquipmentWorkingAnimals extends Component {
  async componentDidMount() {
   
  }

  render() {    
    const equipmentlist = (this.props.equipmentlist) 
    ? this.props.equipmentlist.filter(t => t.type === 'workinganimals') 
    : []

    return (
        <div>                    
            <h2>Working Animals ({ equipmentlist.length } Items)</h2>
            <p>
                This is a list of animals for sale that are generally used for riding, pulling, 
                or service. These are not typically food animals.
            </p>
            <div className="row">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <td>Size</td>
                            <th>Price</th>
                        </tr>
                    </thead>
                    {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <tbody key={r.key} className="bottom-border">                                       
                                <tr className="bolder-headings">
                                    <td>{ r.name }, { r.quality }</td>
                                    <td>{ r.size }</td>
                                    <td>{ r.price } g</td>
                                </tr>     
                                {
                                            (r.equipmentskills) 
                                            ? <tr className='borderless-row'>
                                                <td  colSpan="14"><span className="middle bolder-headings under-emphasis">Used With:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.equipmentskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                                        {
                                            (r.magicskills) 
                                            ? <tr className='borderless-row'>
                                                <td colSpan="14"><span className="middle bolder-headings under-emphasis">Magic Skills:</span>&nbsp;&nbsp;
                                                    <span dangerouslySetInnerHTML={{ __html:  r.magicskills.map(m => (m.score !== 0 ? '(' + m.score +') ' : '') + m.hyperlink).join(', ') } } ></span>
                                                </td> 
                                            </tr>
                                            :null
                                        }
                            </tbody>
                                )
                            : <tr><td>No items...</td></tr>
                        }
                    </Table>        
                <div> {
                        (equipmentlist)
                        ?   equipmentlist.map( r => 
                            <div>
                               <h3>{ r.name }, { r.quality }</h3>
                               <p>
                               { r.description }
                               </p>
                            </div>     
                                )
                        : <tr><td>No items...</td></tr>
                    }
                </div>
            </div>
      </div>
    );
  }
}

export default EquipmentWorkingAnimals;



