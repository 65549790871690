import { connect } from 'react-redux'
import { 
    
} from '../actions'
import ResourcePages from '../components/ResourcePages';

const mapStateToProps = state => {
    return {
        destination: state.destination,
        anchortarget: state.anchortarget,
        taxonomies: state.taxonomies
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePages)
