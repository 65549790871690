import { call, put, takeEvery } from 'redux-saga/lib/effects'
import c from '../constants'
import { loadPageSuccess, loadPageListSuccess } from '../actions'
import { loadPageData, loadPageListData} from '../api'

export function* watchLoadPageAsync(action) {
    try {
      let data = yield call(loadPageData, action.payload)

      yield put(loadPageSuccess(data))
      } catch(e) {
        console.error(e);
      }
  }

export function* watchLoadPageData(action) {
    yield takeEvery (
        c.actions.LOAD_PAGE, 
        watchLoadPageAsync
      )
  }

export function* watchLoadPageListDataAsync(action) {
  try {
    let data = yield call(loadPageListData, action.payload)

    yield put(loadPageListSuccess( { book: action.payload, list: data.pages }))
    } catch(e) {
      console.error(e);
    }
}

export function* watchLoadPageListData(action) {
  yield takeEvery (
      c.actions.LOAD_PAGE_LIST, 
      watchLoadPageListDataAsync
    )
}