import { connect } from 'react-redux'
import WorkingAnimals from '../../components/Inventory/TypeControls/WorkingAnimals'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {    
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkingAnimals)