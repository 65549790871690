import { connect } from 'react-redux'
import {
    loadPageList,
    loadSkillsList
} from '../actions'
import StaticPagesRoot from '../components/StaticPagesRoot';

const mapStateToProps = state => {
    return {
        destination: state.destination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPageList: (book) => {
            dispatch(loadPageList(book))
        },
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPagesRoot)
