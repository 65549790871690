import { connect } from 'react-redux'
import { 
    equipmentHyperlink,
    loadSkillsList
} from '../actions'
import EquipmentAmmunition from '../components/EquipmentAmmunition';

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist,
        skillslist: state.skillslist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        },
        equipmentHyperlink() {
            dispatch(equipmentHyperlink())
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentAmmunition)
