import { connect } from 'react-redux'
import Weapons from '../../components/Inventory/TypeControls/Weapons'

const mapStateToProps = state => {
    return {
        equipmentlist: state.equipmentlist
    }
}

const mapDispatchToProps = dispatch => {
    return {   
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Weapons)