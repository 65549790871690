import { connect } from 'react-redux'
import { 
    loadPage,
    hyperlink,
    loadSkillsList,
    loadPageList
} from '../actions'
import StaticPages from '../components/StaticPages';

const mapStateToProps = state => {
    return {
        destination: state.destination,
        anchortarget: state.anchortarget,
        page: state.page,
        skillslist: state.skillslist,
        pagelist: state.pagelist
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPage: id => {
            dispatch(loadPage(id))
        },       
        loadSkillsList: () => {
            dispatch(loadSkillsList())
        },
        loadPageList: () => {
            dispatch(loadPageList())
        },
        hyperlink(value) {
            dispatch(hyperlink('page', value))
        }
    }       
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPages)
